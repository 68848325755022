<!--ngPrime table-->
<div *ngIf="screen && clientsListTableInformation && clientsListTableSort && isLazyLoad" class="container">
  <p-table #dt [columns]="displayedColumns" [value]="clients" dataKey="id" [lazy]="lazyListening"
           [rowHover]="true"
           [styleClass]="themeName == 'app-theme-dark' ? 'p-datatable-sm p-datatable-dark' : 'p-datatable-sm'"
           [rows]="10" [showCurrentPageReport]="true"
           [paginator]="false"
           [responsive]="false"
           [resizableColumns]="false" responsiveLayout="scroll"
           selectionMode="single"
           [(selection)]="selectedClient"
           (onLazyLoad)="loadLazy($event)"
           stateStorage="session" stateKey="primeng-filter-session"
  >

<!--  table header  -->
    <ng-template pTemplate="header" let-columns>
<!--      column's names-->
      <tr class="p-table-header">
        <ng-container *ngFor="let col of columns">
          <!--Check Box-->
          <th *ngIf="col == 'check' && isClientMerge"
              [ngClass]="{ 'hide-column': screen.device === 'handset' }">
          </th>

          <!-- Provider status Alert -->
          <th *ngIf="col == 'provider-status'"
              [ngClass]="{ 'hide-column': screen.device === 'handset' && screen.portrait }">
          </th>

          <!--Last Name-->
          <th *ngIf="col == 'lastName'"
              [ngClass]="{ 'hide-column': screen.device === 'handset' }"
              class="mat-column-lastName"
              pSortableColumn="last_name"
          >
            Last Name
            <p-sortIcon field="last_name"></p-sortIcon>
            <p-columnFilter [display]="'menu'" type="text" field="last_name" [showAddButton]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <input pInputText [ngModel]="value" (change)="filterCallbackFun(filter, $event.target.value, col)" >
              </ng-template>
            </p-columnFilter>

          </th>

          <!-- First Name-->
          <th *ngIf="col == 'firstName'"
              [ngClass]="{ 'hide-column': screen.device === 'handset' }"
              class="mat-column-firstName"
              pSortableColumn="first_name">First Name
            <p-sortIcon field="first_name"></p-sortIcon>
            <p-columnFilter type="text" field="first_name" display="menu" [showAddButton]="false">
<!--              <ng-template pTemplate="filter" let-value let-filter="filterCallback">-->
<!--                <input pInputText [ngModel]="$any(value)" (change)="filterCallbackFun(filter, $event.target?.value, col);" >-->
<!--              </ng-template>-->
            </p-columnFilter>
          </th>

          <!-- Name -> displayed only on Mobile -->
          <th *ngIf="col == 'name' && screen.device == 'handset'"
              [ngClass]="{ 'hide-column': screen.device !== 'handset' }"
              class="mat-column-name"
              pSortableColumn="first_name">Name
            <p-columnFilter [display]="'menu'" type="text" field="first_name" [showAddButton]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <input pInputText [ngModel]="value" (change)="filterCallbackFun(filter, $event.target.value, col)" >
              </ng-template>
            </p-columnFilter>
          </th>

          <!-- Date Of Birth-->
          <th *ngIf="col == 'dateOfBirth'"
              [ngClass]="{ 'hide-column': screen.device === 'handset' && screen.portrait}"
              class="mat-column-dateOfBirth"
              pSortableColumn="date_of_birth">Date of Birth
            <p-sortIcon field="date_of_birth"></p-sortIcon>
            <p-columnFilter [display]="'menu'" type="date" field="date_of_birth" [showAddButton]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback" >
                <p-calendar [ngModel]="value" (onSelect)="filterCallbackFun(filter, $event, col)" ></p-calendar>
              </ng-template>
            </p-columnFilter>
          </th>

          <!-- Date Of Injury-->
          <th *ngIf="col == 'dateOfInjury'"
              [ngClass]="{ 'hide-column': screen.device === 'handset' && screen.portrait}"
              class="mat-column-dateOfInjury"
              pSortableColumn="date_of_injury">Date of Injury
            <p-sortIcon field="date_of_injury"></p-sortIcon>
            <p-columnFilter [display]="'menu'" type="date" field="date_of_injury" [showAddButton]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-calendar [ngModel]="value" (onSelect)="filterCallbackFun(filter, $event, col)" ></p-calendar>
              </ng-template>
            </p-columnFilter>
          </th>

          <!-- Balance-->
          <th *ngIf="col == 'balance'"
              [ngClass]="{ 'hide-column': screen.device === 'handset' && screen.portrait}"
              class="mat-column-balance"
              pSortableColumn="balance">Balance
            <p-sortIcon field="balance"></p-sortIcon>
            <p-columnFilter [display]="'menu'" type="numeric" field="balance" [showAddButton]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
<!--                <p-inputNumber [ngModel]="value" (change)="filterCallbackFun(filter, $event, col)" mode="currency" currency="USD"></p-inputNumber>-->
                <input pInputText type="number" inputmode="numeric"  [ngModel]="value" (change)="filterCallbackFun(filter, $event.target.value, col)" >
              </ng-template>
            </p-columnFilter>
          </th>

          <!-- Provider Organization / Law Firm User-->
          <th *ngIf="col == 'provider' && roles.includes('user_attorney')"
              [ngClass]="{ 'hide-column': screen.device === 'handset' && screen.portrait}"
              class="p-column-provider-lawFirm" pSortableColumn="provider">
            Provider
            <p-sortIcon field="providers"></p-sortIcon>
            <p-columnFilter [display]="'menu'" type="text" field="providers" [showAddButton]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-dropdown [ngModel]="value" [options]="providersList" placeholder="Select a Provider"
                            (onChange)="filterCallbackFun(filter, $event.value, col)" [showClear]="true">
                  <ng-template let-item pTemplate="item">{{item}}</ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>
          </th>
          <!-- Provider Organization / Provider User-->
          <th *ngIf="col == 'provider' && !roles.includes('user_attorney')"
              [ngClass]="{ 'hide-column': screen.device === 'handset' && screen.portrait}"
              class="p-column-provider" pSortableColumn="provider">
            Provider
            <p-sortIcon field="provider"></p-sortIcon>
            <p-columnFilter [display]="'menu'" type="text" field="provider" [showAddButton]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-dropdown [ngModel]="value" [options]="providersList" placeholder="Select a Provider"
                            (onChange)="filterCallbackFun(filter, $event.value, col)" [showClear]="true">
                  <ng-template let-item pTemplate="item">{{item}}</ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>
          </th>

          <!-- Shared Organization -->
          <th *ngIf="col == 'sharedOrganizations'"
              [ngClass]="{ 'hide-column': screen.device === 'handset' && screen.portrait}"
              class="p-column-sharedOrganizations"
              pSortableColumn="shared_organizations">Shared Organization
            <p-sortIcon field="shared_organizations"></p-sortIcon>
            <p-columnFilter [display]="'menu'" type="text" field="shared_organizations" [showAddButton]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-dropdown appendTo="body" class="sharedOrganizationsDropdown" [ngModel]="value" [options]="sharedOrganizationList" optionLabel="shared_organization" optionValue="shared_organization" placeholder="Select a Organization"
                            (onChange)="filterCallbackFun(filter, $event.value, col)" [showClear]="true">
                  <ng-template let-item pTemplate="item"><span matTooltip="{{item.shared_organization}}">{{item.shared_organization}}</span></ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>
          </th>

          <!-- Attorney status Alert -->
          <th *ngIf="col == 'attorney-status'"
              [ngClass]="{ 'hide-column': screen.device === 'handset' && screen.portrait}">
          </th>

          <!-- Case_status Law firm user-->
          <th *ngIf="col == 'statuses' && roles.includes('user_attorney')"
              [ngClass]="{ 'hide-column': screen.device === 'handset' && screen.portrait}"
              class="p-column-statuses-lawfirm"
              pSortableColumn="client.client_status.status_value">Case Status
            <p-sortIcon field="client.client_status.status_value"></p-sortIcon>
            <p-columnFilter matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"
                            field="statuses">
              <ng-template pTemplate="header">
                <div class="status-header">
                  <span style="font-weight: bold;">Select status</span>
                </div>
              </ng-template>
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect appendTo="body" [ngModel]="value" [options]="selectCaseStatuses" placeholder="Any" (onChange)="filterCallbackFun(filter, $event.value, col)"  optionLabel="value" >
                  <ng-template let-option pTemplate="item">
                    <div class="p-multiselect-representative-option">
                      <span class="ml-1">{{option.value}}</span>
                    </div>
                  </ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>

          <!-- Case_status Provider user-->
          <th *ngIf="col == 'statuses' && !roles.includes('user_attorney')"
              [ngClass]="{ 'hide-column': screen.device === 'handset' && screen.portrait}"
              class="p-column-statuses"
              pSortableColumn="client.client_status.status_value">Case Status
            <p-sortIcon field="client.client_status.status_value"></p-sortIcon>
            <p-columnFilter [display]="'menu'" matchMode="startsWith" [showMenu]="true" [showAddButton]="false"
                            [showOperator]="false" [showMatchModes]="false" [showClearButton]="true" [showApplyButton]="true"
                            field="client_status">
              <ng-template pTemplate="header">
                <div class="status-header">
                  <span style="font-weight: bold;">Select status</span>
                </div>
              </ng-template>
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect appendTo="body" [ngModel]="value" [options]="selectCaseStatuses" placeholder="Any"
                               (onChange)="filterCallbackFun(filter, $event.value, col)" optionLabel="value" >
                  <ng-template let-option pTemplate="item">
                    <div class="p-multiselect-representative-option">
                      <span class="ml-1">{{option.value}}</span>
                    </div>
                  </ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>

          <th *ngIf="col == 'actions'"
              class="p-column-actions">Actions
          </th>
        </ng-container>

      </tr>

    </ng-template>

<!--    table body-->
    <ng-template pTemplate="body" let-client let-columns="columns">
      <tr class="p-table-body"
          (click)="selectItem.emit(client)"
          [ngClass]="{
      selected: selectedClient && selectedClient.id === client.id,

      caseUpdateLightTheme:
      (client.client_status?.needs_update || client.merged_needs_update_label || client.merged_needs_status_update_label)
      && roles.includes('user_attorney') && themeName === lightTheme,

      caseUpdateDarkTheme:
      (client.client_status?.needs_update || client.merged_needs_update_label || client.merged_needs_status_update_label)
      && roles.includes('user_attorney') && themeName === darkTheme}"
      >

        <ng-container *ngFor="let col of columns">

          <!-- Check box-->
          <td *ngIf="col === 'check'"
              [ngClass]="{ 'hide-column': screen.device === 'handset' }">
            <mat-checkbox [checked]="selectedMergeClients?.ids.includes(client.id)"
                          (change)="selectMergeClient.emit({value: $event.checked, data: client})">
            </mat-checkbox>
          </td>

          <!-- Provider status Alert -->
          <td *ngIf="col == 'provider-status'"
              [ngClass]="{ 'hide-column': screen.device === 'handset' && screen.portrait }">
            <span>
               <mat-icon class="warning-icon"
                         *ngIf="client.client_status?.status_value == finalizationRequest">
                 warning
               </mat-icon>
               <mat-icon class="danger-icon"
                         *ngIf="client.client_status?.status_value == noLongerRepresented ||
                   client.client_status?.status_value == closedNoRecovery">
                 warning
               </mat-icon>
            </span>
          </td>

          <!-- Last Name-->
          <td *ngIf="col === 'lastName'" [ngClass]="{ 'hide-column': screen.device === 'handset' }">
            {{client.last_name}}
          </td>

          <!-- First Name-->
          <td *ngIf="col === 'name'"
              [ngClass]="{ 'hide-column': screen.device === 'handset' }">
            {{client.first_name}}
          </td>

          <!-- Name -> displayed only on Mobile -->
          <td *ngIf="col === 'firstName'"
              [ngClass]="{ 'hide-column': screen.device !== 'handset' }">
            {{client.first_name}}
          </td>

          <!-- Date Of Birth-->
          <td *ngIf="col === 'dateOfBirth'"
              [ngClass]="{ 'hide-column': screen.device === 'handset' && screen.portrait }">
            {{client.date_of_birth | dateValidator: 'MM/dd/yyyy'}}
          </td>

          <!-- Date Of Injury-->
          <td *ngIf="col === 'dateOfInjury'"
              [ngClass]="{ 'hide-column': screen.device === 'handset' && screen.portrait}">
            {{client.date_of_injury | dateValidator: 'MM/dd/yyyy'}}
          </td>

          <!-- Balance-->
          <td *ngIf="col === 'balance'"
              [ngClass]="{ 'hide-column': screen.device === 'handset' && screen.portrait}">
            {{
            client.balance !== undefined ?
              (client.balance | currency: 'USD':'symbol' | customCurrency) :
              (client?.client_balance?.balance | currency: 'USD':'symbol' | customCurrency)
            }}
          </td>

          <!-- Provider Organization / Law Firm User-->
          <td *ngIf="col == 'provider' && roles.includes('user_attorney')"
              [ngClass]="{ 'hide-column': screen.device === 'handset' && screen.portrait}">
            {{
              (client?.providers | providerNames)
            }}
          </td>

          <!-- Provider Organization / Provider User-->
          <td *ngIf="col == 'provider' && !roles.includes('user_attorney')"
              [ngClass]="{ 'hide-column': screen.device === 'handset' && screen.portrait}">
            {{
              (client?.provider | organizationNames)
            }}
          </td>

          <!-- Shared Organization -->
          <td *ngIf="col === 'sharedOrganizations'"
              [ngClass]="{ 'hide-column': screen.device === 'handset' && screen.portrait}">
            {{client?.shared_organizations | organizationNames}}
          </td>

          <!-- Attorney status Alert -->
          <td *ngIf="col === 'attorney-status'" style="text-align: center;"
              [ngClass]="{ 'hide-column': screen.device === 'handset' && screen.portrait}">
            <span>
              <mat-icon class="warning-icon"
                        *ngIf="client?.client_status?.needs_update ||
                    client.merged_needs_update_label ||
                    client.merged_needs_status_update_label">
                warning
              </mat-icon>
            </span>
          </td>

          <!-- Case_status Law firm User -->
          <td *ngIf="col === 'statuses' && roles.includes('user_attorney')"
              matTooltip="{{tooltipText}}"
              (mouseover)="getTooltipText(client?.client_status?.status_value, true)"
              [ngClass]="{ 'hide-column': screen.device === 'handset' && screen.portrait}"
              class="p-column-statuses"
          >
            <button mat-icon-button [autofocus]="false" style="width: 5%;"
                    [matMenuTriggerFor]="status">

              <span class="status-value"
                    [ngClass]="{'warning-text': client.client_status?.needs_update ||
                 client.merged_needs_update_label ||
                 client.merged_needs_status_update_label}"
              >
                {{(client.client_status?.needs_update && !client.merged_needs_update_label) ? client.client_status?.status_value :
                client.merged_needs_update_label ? 'Need Case Update' :
                  client.merged_needs_status_update_label ? 'Need Case Update' :
                    !client.client_status?.status_value ? 'New Patient' :
                      client.client_status?.status_value
                }}
              </span>
                <mat-icon>arrow_drop_down</mat-icon>

            </button>
            <mat-menu #status="matMenu">
              <button class="status" mat-menu-item
                      matTooltip="{{tooltipText}}"
                      (mouseover)="getTooltipText(status.value, roles.includes('user_attorney'))"
                      *ngFor="let status of selectCaseStatuses">

                <span>{{status.value}}</span>

                <mat-icon color="primary" (click)="onChangeStatus(client.id, status.id)">send</mat-icon>
                <!-- <mat-icon color="primary">note_add</mat-icon>-->
              </button>
            </mat-menu>
          </td>

          <!-- Case_status Provider User-->
          <td *ngIf="col === 'statuses' && !roles.includes('user_attorney')"
              matTooltip="{{tooltipText}}"
              (mouseover)="getTooltipText(client?.client_status?.status_value, false)"
              [ngClass]="{ 'hide-column': screen.device === 'handset' && screen.portrait}"
              class="p-column-statuses"
          >
            <button mat-icon-button [autofocus]="false" style="width: 5%;"
                    class = "disableStatusMenu">

              <span class="status-value"
                    [ngClass]="{'warning-text': client.client_status?.status_value == finalizationRequest ,
                              'danger-text': client.client_status?.status_value == noLongerRepresented ||
                               client.client_status?.status_value == closedNoRecovery}">

              {{client.client_status?.status_value == finalizationRequest ?
                client.client_status?.status_value + '...' :
                !client.client_status?.status_value ? 'Awaiting Case Update' : client.client_status?.status_value}}

              </span>

            </button>
          </td>

          <!-- Actions: calculate width for the number of enabled actions-->
          <td *ngIf="col === 'actions'"
              class="p-column-actions">
            <button
              *ngIf="clientEdit"
              type="button"
              mat-icon-button
              color="primary"
              matTooltip="Edit"
              (click)="edit.emit(client)"
              [matTooltipShowDelay]="750"
            >
              <mat-icon>edit</mat-icon>
            </button>
            <button
              *ngIf="clientDetails"
              type="button"
              mat-icon-button
              color="primary"
              (click)="details.emit(client)"
              matTooltip="Details"
              [matTooltipShowDelay]="750"
            >
              <mat-icon>details</mat-icon>
            </button>
            <button
              *ngIf="clientShare"
              type="button"
              mat-icon-button
              color="primary"
              matTooltip="Share"
              (click)="share.emit(client)"
              [matTooltipShowDelay]="750"
            >
              <mat-icon>share</mat-icon>
            </button>
            <button
              *ngIf="clientRefresh"
              type="button"
              mat-icon-button
              color="accent"
              (click)="refresh.emit(client)"
              matTooltip="Refresh"
              [matTooltipShowDelay]="750"
            >
              <mat-icon>refresh</mat-icon>
            </button>
          </td>
        </ng-container>

      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="8">No clients found.</td>
      </tr>
    </ng-template>
  </p-table>

<!--  table footer-->
  <div class="table-footer">
    <ng-container *ngIf="isClientMerge">
      <button mat-stroked-button [disabled]="selectedMergeClients?.ids.length < 2" (click)="merge.emit()">Merge</button>
      <button mat-stroked-button class="merge" (click)="cancelMerge.emit()">Cancel</button>
    </ng-container>
    <p-paginator
      [styleClass]="themeName == 'app-theme-dark' ? 'p-datatable-dark' : ''"
      (onPageChange)="paginate($event)"
      [showFirstLastIcon]="true"
      [rowsPerPageOptions]="[5, 10, 25, 50, 100]"
      [dropdownScrollHeight]="'300px'"
      [rows]="clientsListTableInformation.pageSize"
      [totalRecords]="count"
    >
    </p-paginator>
  </div>
</div>
