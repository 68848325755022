export const environment = {
  production: false,
  environment: 'FISHBONE',
  firebaseConfig: {
    apiKey: 'AIzaSyDU9ivnsoJ1up1LVEyKHRs7OSwuyq-39hI',
    authDomain: 'sharescapeio-fishbone.firebaseapp.com',
    projectId: 'sharescapeio-fishbone',
    storageBucket: 'sharescapeio-fishbone.appspot.com',
    messagingSenderId: '247492357674',
    appId: '1:247492357674:web:071ece3d2ed8d8ab961d53',
  },
  hasura: {
    https: 'https://fishbone-api.sharescape.io/v1/graphql',
    wss: 'wss://fishbone-api.sharescape.io/v1/graphql',
  },
  apiUrl: 'https://fishbone-api.sharescape.io',
  logToConsole: false,
};
