import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducer } from './+state/core.reducer';
import { CoreEffects } from './+state/core.effects';

import { RouterModule } from '@angular/router';
import { MaterialModule } from '@material/material.module';
import {PrimengModule} from '@app/primeng/primeng.module';


// Containers
import {
  EulaComponent,
  FeedbackComponent,
  NavComponent,
  PrivacyPolicyComponent,
  ThemePickerContainerComponent,
  SuggestProviderComponent, ReferPatientComponent, InitLoginPromptComponent,
  MagicLinkInfoComponent
} from './containers';

// Components
import {
  AddressFormComponent,
  ChiroTouchImportPromptComponent,
  ClientDetailsComponent,
  ClientsListComponent,
  DocumentsListComponent,
  DocumentDetailsComponent,
  DocumentNewComponent,
  OrganizationsListPaginationComponent,
  OrganizationsListComponent,
  OrganizationDetailsComponent,
  OrganizationsPopupComponent,
  OrganizationsClientsListComponent,
  OrganizationUsersListComponent,
  ChooseFilenameComponent,
  DropZoneComponent,
  EulaVerbiageComponent,
  FooterComponent,
  HeaderComponent,
  NavTreeComponent,
  NavItemComponent,
  PrivacyPolicyVerbiageComponent,
  ProfileComponent,
  ThemePickerComponent,
  PhoneFormComponent,
  TransactionListComponent,
  TransactionBillDetailsComponent,
  TransactionPaymentDetailsComponent,
  TransactionDetailsComponent,
  UsersListComponent,
  UserDetailsComponent,
  OkCancelPromptComponent,
  ReportUsersByOrganizationComponent,
  ReportOrganizationsComponent,
  UserActionsComponent,
  UploadTransactionDocumentComponent,
  TransactionNewBillComponent,
  TransactionNewPaymentComponent,
  MyOrganizationUsersListComponent,
  ClientCaseContactComponent,
  UpdateProfileNagComponent,
  EmailSettingFormComponent
} from './components';

// Pipes
import {
  CustomCurrencyPipe,
  FormatFileSizePipe,
  LocalDateTimePipe,
  PhoneNumbersPipe,
  OrganizationNamesPipe,
  PhoneDisplayPipe,
  DateValidatorPipe,
  ProviderNamesPipe
} from './pipes';

// Directives
import {
  PhoneMaskDirective,
  SelectFilesDirective,
  DropZoneDirective,
  DateMaskDirective,
} from './directives';
import { SendMagicLinkComponent } from './containers/send-magic-link/send-magic-link.component';

@NgModule({
  declarations: [
    AddressFormComponent,
    ChiroTouchImportPromptComponent,
    ClientDetailsComponent,
    ClientsListComponent,
    DocumentsListComponent,
    DocumentDetailsComponent,
    DocumentNewComponent,
    TransactionListComponent,
    TransactionDetailsComponent,
    TransactionBillDetailsComponent,
    TransactionPaymentDetailsComponent,
    TransactionNewBillComponent,
    TransactionNewPaymentComponent,
    UploadTransactionDocumentComponent,
    OrganizationsListPaginationComponent,
    OrganizationsListComponent,
    OrganizationDetailsComponent,
    OrganizationsPopupComponent,
    OrganizationsClientsListComponent,
    OrganizationUsersListComponent,
    ChooseFilenameComponent,
    DropZoneComponent,
    EulaComponent,
    EulaVerbiageComponent,
    SuggestProviderComponent,
    ReferPatientComponent,
    FeedbackComponent,
    FooterComponent,
    HeaderComponent,
    NavComponent,
    NavTreeComponent,
    PrivacyPolicyComponent,
    PrivacyPolicyVerbiageComponent,
    ProfileComponent,
    ThemePickerContainerComponent,
    ThemePickerComponent,
    CustomCurrencyPipe,
    FormatFileSizePipe,
    LocalDateTimePipe,
    PhoneNumbersPipe,
    OrganizationNamesPipe,
    ProviderNamesPipe,
    PhoneDisplayPipe,
    PhoneMaskDirective,
    SelectFilesDirective,
    DropZoneDirective,
    ProfileComponent,
    PhoneFormComponent,
    UsersListComponent,
    UserDetailsComponent,
    OkCancelPromptComponent,
    UpdateProfileNagComponent,
    ReportUsersByOrganizationComponent,
    ReportOrganizationsComponent,
    UserActionsComponent,
    NavItemComponent,
    DateValidatorPipe,
    DateMaskDirective,
    MyOrganizationUsersListComponent,
    ClientCaseContactComponent,
    InitLoginPromptComponent,
    EmailSettingFormComponent,
    MagicLinkInfoComponent,
    SendMagicLinkComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    PrimengModule,
    StoreModule.forFeature('core', reducer),
    EffectsModule.forFeature([CoreEffects]),
  ],
  exports: [
    AddressFormComponent,
    ChiroTouchImportPromptComponent,
    ClientDetailsComponent,
    ClientsListComponent,
    DocumentsListComponent,
    DocumentDetailsComponent,
    DocumentNewComponent,
    TransactionListComponent,
    TransactionBillDetailsComponent,
    TransactionPaymentDetailsComponent,
    TransactionDetailsComponent,
    TransactionNewBillComponent,
    TransactionNewPaymentComponent,
    UploadTransactionDocumentComponent,
    OrganizationsListPaginationComponent,
    OrganizationsListComponent,
    OrganizationDetailsComponent,
    OrganizationsPopupComponent,
    OrganizationsClientsListComponent,
    OrganizationUsersListComponent,
    NavComponent,
    ChooseFilenameComponent,
    DropZoneComponent,
    CustomCurrencyPipe,
    FormatFileSizePipe,
    LocalDateTimePipe,
    PhoneNumbersPipe,
    OrganizationNamesPipe,
    ProviderNamesPipe,
    PhoneDisplayPipe,
    DateValidatorPipe,
    PhoneMaskDirective,
    SelectFilesDirective,
    DateMaskDirective,
    ProfileComponent,
    DropZoneDirective,
    PhoneFormComponent,
    UsersListComponent,
    UserDetailsComponent,
    OkCancelPromptComponent,
    UpdateProfileNagComponent,
    ReportUsersByOrganizationComponent,
    ReportOrganizationsComponent,
    UserActionsComponent,
    MyOrganizationUsersListComponent,
    ClientCaseContactComponent,
    EmailSettingFormComponent
  ],
})
export class CoreModule {}
